<template>
  <v-container class="my-3">
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("service registered successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not add a service. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-row justify="space-between">
      <v-row justify="space-between" class="mb-1">
        <v-col cols="12" md="6">
          <h2 class="headline text-capitalize">
            {{ $t("new service entry") }}
          </h2>
          <small>*{{ $t("indicates required field") }}.</small>
        </v-col>

        <v-col cols="12" md="6" align-self="end" class="text-right">
          <v-btn
            small
            dark
            class="mr-4"
            :color="$store.state.primaryColor"
            @click="demoForm"
          >
            <v-icon small left>gamepad</v-icon>
            <span class="caption text-capitalize">Demo</span>
          </v-btn>
          <v-btn
            small
            dark
            :loading="loading"
            :color="$store.state.secondaryColor"
            @click="uploadData"
          >
            <v-icon small left>save</v-icon>
            <span class="caption text-capitalize">{{ $t("register") }}</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-alert>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-row dense class="caption">
                  <v-col cols="12" md="4">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("business name") }}* :
                    </span>
                    <v-text-field
                      v-if="selectedBusiness"
                      outlined
                      dense
                      v-model="selectedBusinessName"
                      readonly
                      :rules="rules.emptyField"
                    ></v-text-field>

                    <v-select
                      v-else
                      outlined
                      dense
                      item-text="businessName"
                      item-value="id"
                      :items="ownersBusinesses"
                      v-model="service.businessID"
                      :rules="rules.emptyField"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("name of service") }}* :
                    </span>
                    <v-textarea
                      outlined
                      dense
                      auto-grow
                      rows="1"
                      v-model="service.name"
                      :rules="rules.emptyField"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span class="font-weight-medium text-capitalize"
                      >{{ $t("category of service") }}* :
                    </span>
                    <v-textarea
                      outlined
                      dense
                      auto-grow
                      rows="1"
                      v-model="service.category"
                      :rules="rules.emptyField"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" md="4">
                    <span class="font-weight-medium text-capitalize">
                      {{ $t("description") }}* :
                    </span>
                    <v-textarea
                      outlined
                      dense
                      auto-grow
                      rows="1"
                      v-model="service.description"
                      :rules="rules.emptyField"
                    ></v-textarea>
                  </v-col>

                  <v-col cols="12" md="4">
                    <span class="font-weight-medium"
                      >{{ $t("selling price") }}* :
                    </span>
                    <v-text-field
                      @keypress="digits"
                      outlined
                      dense
                      v-model.trim="service.price.selling"
                      :rules="rules.number"
                    ></v-text-field>
                  </v-col>

                  <!-- Note: How to price services?  -->
                  <v-col cols="12" md="4">
                    <span class="font-weight-medium"
                      >{{ $t("limit price") }}* :
                    </span>
                    <v-text-field
                      @keypress="digits"
                      outlined
                      dense
                      v-model.trim="service.price.limit"
                      :rules="rules.number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <span class="font-weight-medium"
                      >{{ $t("discount") }}* :
                    </span>
                    <v-text-field
                      outlined
                      dense
                      @keypress="digits"
                      v-model.trim="service.price.discount"
                      :rules="rules.number"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-alert>
    </v-row>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";

export default {
  data: () => ({
    snackSuccess: false,
    snackError: false,
    timeout: 2000,
    loading: false,
    service: {
      name: "",
      businessID: "",
      category: "",
      description: "",
      price: {
        selling: "",
        limit: "",
        discount: "",
      },
      dateCreated: new Date(),
    },
  }),

  computed: {
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
        number: [
          (v) => !!v || this.$t("this field is required"),
          (v) => !isNaN(v) || this.$t("please enter a number"),
        ],
      };
    },
  },
  methods: {
    uploadData() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        db.collection("services")
          .add(this.service)
          .then(() => {
            this.loading = false;
            this.snackSuccess = true;
            setTimeout(() => this.goBack(), 2000);
          })
          .catch(() => {
            this.loading = false;
            this.snackError = true;
          });
      }
    },

    demoForm() {
      if (this.selectedBusiness) {
        this.service.name = "Filing tax returns";
        this.service.businessID = this.selectedBusiness;
        this.service.category = "Financial services";
        this.service.description = "Tax returns for  SMEs";
        this.service.price.selling = "20000";
        this.service.price.limit = "18500";
        this.service.price.discount = "0";
      } else {
        this.service.name = "Tax Services";
        this.service.businessID = this.ownersBusinesses[0].id;
        this.service.category = "Financial services";
        this.service.description = "Tax returns for  SMEs";
        this.service.price.selling = "20000";
        this.service.price.limit = "18500";
        this.service.price.discount = "0";
      }
    },

    digits(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        // 46 is dot 43 is +
        event.preventDefault();
      }
    },

    goBack() {
      this.$router.push("/services");
    },
  },
};
</script>

<style></style>
